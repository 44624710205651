import Sortable from "stimulus-sortable"

export default class extends Sortable {
  static targets = [ "row" ]

  connect() {
     super.connect()
  }
  end() {
    this.rowTargets.forEach((row, index)=>{
      row.querySelector(".dragInput").value = index + 1;
      var event = new Event('change');
      row.querySelector(".dragInput").dispatchEvent(event);
    })
  }

}
