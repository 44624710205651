import places from 'places.js';

export const initAddressAutocomplete = () => {
  const addressInputElts = document.querySelectorAll('.address-autocomplete');
  addressInputElts.forEach(addressInputElt => {
    if (addressInputElt) {
      places({ container: addressInputElt });
    }
  })
};
