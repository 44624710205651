import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "fields", "destroyCheckbox", "button" ]
  static values = { display: Boolean, possibleData: Array }

  connect() {
    this.displayFieldsIfRecord();
  }

  displayFieldsIfRecord() {
    if(this.element.dataset.recordExists == "true") {
      this.fieldsTarget.classList.remove("d-none");
      const btn = this.element.querySelectorAll("div[data-input-target='button']")[1];
      btn.click()
    } else {
      this.destroyCheckboxTarget.value = true;
    }
  }

  hideAndDestroy() { // = click on "Non"
    this.fieldsTarget.classList.add("d-none");
    this.destroyCheckboxTarget.value = true;
  }

  show(e) { // = click on "Oui"
    this.fieldsTarget.classList.remove("d-none");
    this.destroyCheckboxTarget.value = false;
  }
}
