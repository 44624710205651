import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "container" ]

  connect() {
    this.updateTargetVisibility();
  }

  updateTargetVisibility() {

    this.containerTargets.forEach((target)=> {
      target.classList.add("visibility-hidden");
    })
    this.inputTargets.forEach((input)=>{
      if (input.checked) {
        this.element.querySelectorAll(input.dataset.container).forEach((container)=> {
          container.classList.remove("visibility-hidden");
        })
      }
    })

  }
}
