import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { AttributionControl } from "mapbox-gl";

export default class extends Controller {
  static targets = [ "submit" ];

  connect() {
  }

  submitOnChange() {
    this.submitTarget.click();
  }

  updateOptions(data, dropdown, additional) {
    // TODO delete jquery
    let select = $(dropdown).empty();
    if (additional != "") {
      select.append(`<option value="">${additional}</option>`)
    }
    data.forEach(element => {
      select.append(`<option value="${element.id}">${element.text}</option>`)
    });
    this.submitTarget.click();
  }

  updateDropdown(e) {
    const additionalEmpty = e.currentTarget.dataset.additionalEmpty;
    console.log("Request to ");
    Rails.ajax({
      type: "get",
      url: e.currentTarget.dataset.url,
      data: `phase_id=${e.currentTarget.value}`,
      success: (data) => {
        this.updateOptions(data, document.getElementById("filter_hens"), additionalEmpty);
      }
    })
  }
}
