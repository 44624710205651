require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

var Turbolinks = require("turbolinks")
Turbolinks.start()

// External imports
import "bootstrap";
// Internal imports
import { initAddressAutocomplete } from "../plugins/init_address_autocomplete"
import { initCookieBanner } from '../components/cookies'
import { autoOpenModal } from '../components/modal'
import { initMapbox } from '../plugins/init_mapbox';
import { initSelect2 } from '../plugins/init_select2';
import { initFlatpickr } from "../plugins/flatpickr";
import "controllers"

document.addEventListener('turbolinks:load', () => {
  initAddressAutocomplete();
  initCookieBanner();
  autoOpenModal();
  initMapbox();
  initSelect2();
  initFlatpickr();
});


