import { Controller } from "stimulus"
import { Tooltip } from 'bootstrap'
// import { bootstrap } from 'bootstrap.esm.js'
export default class extends Controller {

  connect() {
    this.initBsTooltips();
  }

  initBsTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
  }
}
