import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["url"];

  connect() {
    this.reloadIfAgendaDone(1000)
  }

  reloadIfAgendaDone(time) {
    const url = this.urlTarget.dataset.url
    setTimeout(async () => {
      const response = await fetch(url).then(response => response.json())
        if (response[0]["status"] == "agenda_done") {
          location.reload();
        } else {
          this.reloadIfAgendaDone(3000)
        }
    }, time)

  }
}
