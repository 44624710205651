import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "input" ]

  connect() {
    this.update();
  }
  update() {
    if (this.inputTargets.every(input=>input.value == input.dataset.hiddenValue)) {
      this.containerTargets.forEach((container) => {
        container.classList.add("visibility-hidden");
      })
    } else {
      this.containerTargets.forEach((container) => {
        container.classList.remove("visibility-hidden");
      })
    }
  }
}
