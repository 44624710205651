import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "container" ]

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    let visible = true
    this.inputTargets.forEach((input)=>{
      console.log(input.dataset.hideValue);
      if (input.checked && input.dataset.hideValue == input.value) {
        visible = false
      }
    })
    if (visible) {
      this.containerTarget.classList.remove("visibility-hidden");
    } else {
      this.containerTarget.classList.add("visibility-hidden");
    }

  }
}
