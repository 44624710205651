import { Controller } from "stimulus";
// TODO Merge this controller with input controller

export default class extends Controller {
  static targets = [ "container", "button", "field" ]; // SAME AS INPUT CONTROLLER BUT REDUCED
  static classes = [ "active", "inactive" ] // SAME AS INPUT CONTROLLER
  static values = { possibleData: Array } // DIFFERENT FROM INPUT CONTROLLER

  connect() {
    this.keepStateIfValues();
  }

  toggleColors() { // SAME AS INPUT CONTROLLER
    this.buttonTargets.forEach((button) => {
      button.classList.toggle(this.inactiveClass);
      button.classList.toggle(this.activeClass);
    });
  }

  switchBtnsColors(event) { // SAME AS INPUT CONTROLLER
    if(event.currentTarget.classList.contains("form-btn-inactive")) {
      this.toggleColors();
    }
  }

  switchValue(event) { // DIFFERENT FROM INPUT CONTROLLER (usable with anything)
    if (this.hasFieldTarget) {
      const field = this.fieldTarget;
      const changeEvent = new Event('change', { bubbles: true });
      const value = event.currentTarget.dataset.value
      field.value = value
      field.dispatchEvent(changeEvent)
    }
  }

  hideAndClean(container, input) { // NOT IN INPUT CONTROLLER
    container.classList.add("d-none");
    this.clean(input);
  }

  switchField(event) { // NOT IN INPUT CONTROLLER
    const containers = this.containerTargets;
    const fakeInputs = this.element.querySelectorAll(".fakeInput");
    const realInputs = this.fieldTargets;
    const clickedBtnIndex = Array.prototype.indexOf.call(fakeInputs, event.currentTarget);
    if(clickedBtnIndex == 0) {
      this.hideAndClean(containers[1], realInputs[1]);
      containers[0].classList.remove("d-none")
    } else if(clickedBtnIndex == 1) {
      containers[1].classList.remove("d-none");
      this.hideAndClean(containers[0], realInputs[0]);
    }
  }

  clean(input) { // NOT IN INPUT CONTROLLER
    if(input.tagName === "SELECT") {
      input.selectedIndex = 1;
    } else if(input.tagName === "INPUT") {
      input.value = null;
    }
  }

  resetValue(event) { // DIFFERENT FROM INPUT CONTROLLER
    const target = this.fieldTarget;
    this.clean(target);
  }

  hideContainer() { // SAME AS INPUT CONTROLLER
    if (this.hasContainerTarget) {
      this.containerTarget.classList.add("visibility-hidden");
    }
  }

  unhideContainer() { // SAME AS INPUT CONTROLLER
    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove("visibility-hidden");
    }
  }

  activateBtn(button) {
    button.classList.add(this.activeClass)
    button.classList.remove(this.inactiveClass)
  }

  deactivateBtn(button) {
    button.classList.remove(this.activeClass)
    button.classList.add(this.inactiveClass)
  }

  keepStateIfValues() { // DIFFERENT FROM INPUT CONTROLLER
    if (!this.hasFieldTarget) {
      return
    }
    const targets = this.fieldTargets;
    if (targets.length == 1) {
      const target = targets[0];
      if(target.tagName === "SELECT" && target.selectedIndex !== 1 || target.tagName === "INPUT" && target.value !== "") {
        this.unhideContainer();
        if (this.buttonTarget.classList.contains("fakeInput")) {
          this.activateBtn(this.buttonTargets[1])
          this.deactivateBtn(this.buttonTargets[0])
        } else {
          const value = this.fieldTarget.value;
          const activeBtn = this.buttonTargets.find(button => button.dataset.value === value);
          this.buttonTargets.forEach((button) => {
            if (button == activeBtn) {
              this.activateBtn(button)
            } else {
              this.deactivateBtn(button)
            }
          })
        }
      }
    } else if (targets.length > 1) {
      const inputWithValue = targets.find(target => target.value !== "") // Only works for inputs of type input (ie not select inputs)
      const inputIndex = Array.prototype.indexOf.call(targets, inputWithValue);
      if (inputIndex > -1) {
        let containerTargets = this.containerTargets;
        containerTargets[inputIndex].classList.remove("d-none");
        containerTargets.splice(inputIndex, 1)
        containerTargets.forEach((container) => {
          container.classList.add("d-none");
        })
        let btnTargets = this.buttonTargets;
        this.activateBtn(btnTargets[inputIndex])
        btnTargets.splice(inputIndex, 1)
        btnTargets.forEach((button) => {
          this.deactivateBtn(button)
        })
      }
    }
  }
}
