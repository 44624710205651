import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["container", "rightBtn", "leftBtn"];
  static values = { scroll: String }

  scrollRight() {
    this.scrollValue = "true"
    this.containerTarget.scrollLeft += 40;
    this.triggerScrollRight(this);
  }

  stopScroll() {
    this.scrollValue = "false"
  }
  scrollLeft() {
    this.scrollValue = "true"
    this.containerTarget.scrollLeft -= 40;
    this.triggerScrollLeft(this);
  }
  triggerScrollRight(element) {
    setTimeout( function(element){
      var scroll = element.scrollValue == "true"
      console.log(this.scrollValue)
      if(scroll) {
        element.containerTarget.scrollLeft += 40;
        element.triggerScrollRight(element)
      }
    }, 50, element)
  }

  triggerScrollLeft(element) {
    setTimeout( function(element){
      var scroll = element.scrollValue == "true"
      console.log(element.rightBtnTarget.dataset)
      if(scroll) {
        element.containerTarget.scrollLeft -= 40;
        element.triggerScrollLeft(element)
      }
    }, 50, element)
  }
}
