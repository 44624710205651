import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "triggerInput" ]

  // This controller manages conditional fields ie fields that must or must not be displayed depending on the value selected on one input

  connect() {
    const selectedValue = this.triggerInputTarget.value;
    if(selectedValue) {
      this.toggleFields(selectedValue);
    }
  }

  toggleFields(selectedValue) {
    this.containerTargets.forEach((container) => {
      const triggers = JSON.parse(container.dataset.triggers);
      if(triggers.find(trigger => trigger === selectedValue)){
        container.classList.remove("d-none");
        container.querySelectorAll(".destroyCheckbox").forEach((checkbox) => {
          if (checkbox.dataset.btnNoBonus) {
            checkbox.value = document.querySelector(checkbox.dataset.btnNoBonus).classList.contains("form-btn-active");
          } else {
            checkbox.value = false;
          }

        })
      } else {
        container.classList.add("d-none");
        container.querySelectorAll(".destroyCheckbox").forEach((checkbox) => {
          checkbox.value = true;
        })
      }
    })
  }

  updateFields(e) {
    const value = e.currentTarget.value;
    this.toggleFields(value);
  }

}
