import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "section", "toolbar", "button" ]
  static values = { "activeSectionIndex": Number }

  connect() {
    this.updateToolbarColors()
  }

  updateToolbarColors() {
    const number = this.activeSectionIndexValue;
    const activeSection = this.sectionTargets[number];
    activeSection.classList.add("active");
  }

  updateToolbarDisplay() {
    this.toolbarTarget.classList.toggle("left-500-neg-under-lg");
    this.buttonTarget.classList.toggle("d-none");
  }
}
