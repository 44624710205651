import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["container"];

  updateToShow() {
    this.containerTarget.dataset.displayStatus = "show";
  }

  updateToHide() {
    this.containerTarget.dataset.displayStatus = "hide";
  }

}
