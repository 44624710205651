import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";

const initFlatpickr = () => {
  document.querySelectorAll(".datepicker").forEach((datePicker)=> {

    let datPickerInstance = flatpickr(datePicker, {
      altInput: true,
      altFormat: "j F Y",
      locale: French
    });
    if (datePicker.dataset.startDate) {
      var defaultDate = new Date (datePicker.dataset.startDate)
      datPickerInstance.jumpToDate(defaultDate, true)
    }

  })
}


export { initFlatpickr };
