import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["spinnerContainer"];

  triggerSpinner() {
    var spinnerCss = this.spinnerContainerTarget.dataset.spinnerCss
    this.spinnerContainerTarget.innerHTML = `<div class='d-flex ${spinnerCss} align-items-center justify-content-center'><div class='spinner-border' role='status'><span class='visually-hidden'>Loading...</span></div></div>`;
  }
}
