import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { AttributionControl } from "mapbox-gl";

export default class extends Controller {
  static targets = [ "submit", "dropdown", "label", "wrapper" ];

  connect() {
  }

  updateOptions(title, collection, dropdown, additional) {
    // TODO delete jquery
    let select = $(dropdown).empty();
    select.append(`<option value="">${additional}</option>`)
    collection.forEach(element => {
      select.append(`<option value="${element.id}">${element.text}</option>`)
    });
    this.labelTarget.textContent = title;
    if (collection.length == 0) {
      this.wrapperTarget.classList.add("d-none")
    } else {
      this.wrapperTarget.classList.remove("d-none")
    }
  }

  updateDropdown(e) {
    const additionalEmpty = e.currentTarget.dataset.additionalEmpty;
    Rails.ajax({
      type: "get",
      url: e.currentTarget.dataset.url,
      data: `${e.currentTarget.dataset.param}=${e.currentTarget.value}`,
      success: (data) => {
        this.updateOptions(data["title"], data["collection"], this.dropdownTarget, additionalEmpty);
      }
    })
  }
}
