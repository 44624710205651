import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "navlink" ]

  connect() {
    this.updateNavbar()
  }

  getPagePath() {
    return window.location.pathname
  }

  updateNavbar() {
    const pagePath = this.getPagePath();
    this.navlinkTargets.forEach(link => {
      if(pagePath == link.attributes.href.value) {
        link.classList.add("btn", "btn-gray", "rounded-pill")
      } else {
        link.classList.remove("btn", "btn-gray", "rounded-pill")
      }
    });
  }
}
