import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["check", "value", "select", "form", "container", "containers", "button", "field", "hiddenField", "file", "filename", "inputContainer", "inputKeepValue"];
  static classes = [ "active", "inactive" ]
  static values = { "customForm": Boolean }

  connect() {
    if(this.customFormValue == true) {
      this.keepStateIfValues();
    }
  }

  checkInput(){
    this.checkTarget.checked = true;
    this.addCheckedToContainer()
  }

  uncheckInput(){
    this.checkTarget.checked = false;
    this.addCheckedToContainer()
  }
  copyValueToAssociateInput() {

    this.checkTargets.forEach((checkBox)=>{
      checkBox.checked = event.currentTarget.checked;
    })
  }
  // Pour vider les inputs d'un formulaire
  removeValues() {
    this.checkTargets.forEach( (checkObject)=> {
      checkObject.checked = false
    })
    this.valueTargets.forEach( (valueObject)=> {
      valueObject.value = ""
    })
    this.selectTargets.forEach( (selectObject)=> {
      selectObject.select = ""
    })
    const event = new Event('change');
    this.formTarget.dispatchEvent(event);
  }
  addCheckedToContainer() {
    if (this.checkTarget.checked) {
      this.containerTarget.classList.add("input-checked");
    } else {
      this.containerTarget.classList.remove("input-checked");
    }
  }

  toggleColors() {
    this.buttonTargets.forEach((button) => {
      button.classList.toggle(this.inactiveClass);
      button.classList.toggle(this.activeClass);
    });
  }

  switchBtnsColors(event) {
    if(event.currentTarget.classList.contains("form-btn-inactive")) {
      this.toggleColors();
    }
  }

  switchEditionFormat(event) {
    const field = this.hiddenFieldTarget;
    if(field.value == "player") {
      field.value = "team";
    } else {
      field.value = "player";
    }
  }

  resetValue() {
    const target = this.fieldTarget;
    if(target.tagName === "SELECT") {
      target.selectedIndex = 1;
    } else if(target.tagName === "INPUT") {
      target.value = 0;
    }
  }
  UpdateContainerVisibilityByCheckedValue() {
    if (event.currentTarget.checked) {
      this.inputContainerTarget.classList.remove("visibility-hidden");
    } else {
      this.inputContainerTarget.classList.add("visibility-hidden");
      this.valueTargets.forEach( (valueObject)=> {
        valueObject.value = "";
      })
    }
  }
  hideContainer() {
    if (this.hasContainerTarget) {
      this.containerTarget.classList.add("visibility-hidden");
    }
  }
  unhideContainer() {
    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove("visibility-hidden");
    }
  }
  hideContainers() {
    this.containersTargets.forEach((container) => { container.classList.add("visibility-hidden"); })
  }

  unhideContainers() {
    this.containersTargets.forEach((container) => { container.classList.remove("visibility-hidden"); })
  }

  keepStateIfValues() {
    if (!this.hasFieldTarget) {
      return
    }
    const target = this.fieldTarget;
    if (target.dataset.skipKeepValue) {
      return
    }
    if(target.tagName === "SELECT" && target.selectedIndex !== 1 || target.tagName === "INPUT" && target.value !== "0") {
      this.unhideContainers();
      this.unhideContainer();
      this.toggleColors();
    }
  }

  updateFilename(event) {
    this.filenameTarget.innerHTML = event.currentTarget.files[0].name
  }
}
