import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hiddenField", "label", "button" ]

  update(e) {
    const value = e.currentTarget.dataset.hiddenFieldValue
    this.hiddenFieldTargets.forEach((target) => {
      target.value = value;
    })
    const title = e.currentTarget.dataset.hiddenFieldTitle
    this.labelTargets.forEach((label) => {
      label.innerHTML = title;
    })
  }
}
