import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["activable"];

  addActive() {
    this.activableTargets.forEach((target)=>{
      target.classList.remove("active");
    })
    event.currentTarget.classList.add("active");
  }

}
