import { Controller } from "stimulus";
// TODO split logics into different controllers in order to avoid complicated/multi-purpose controllers
export default class extends Controller {
  static targets = ["container"];

  hideContainers() {
    this.containerTargets.forEach((container)=>{
      if (event.currentTarget.dataset.displayStatus != "show") {
        container.classList.add("d-none");
      }
    })
  }
  showContainers() {
    this.containerTargets.forEach((container)=>{
      container.classList.remove("d-none");
    })
  }

}
