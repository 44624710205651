import $ from 'jquery';
import 'select2';

const initSelect2 = () => {
  $('.select2-container').remove()
  $('.select2').select2({ width: '100%',dropdownAutoWidth : true });
  $('.select2').on('select2:select', function (e) {
    const event = new Event('change');
    var form = e.currentTarget.closest("form");
    form.dispatchEvent(event);
  });
};
export { initSelect2 };
